//get the number of carousel items to show based on the given screen size
function getResponsiveCarouselLength(carousel_length, size = null) {
    if (carousel_length === 6) {
        switch(true) {
            case size == 'lg':
                return 6;
            case size == 'md':
                return 5;
            case size == 'sm':
                return 4;
            case size == 'xs':
                return 3;
            default:
                return 2;
        }
    } else if (carousel_length === 2) {
        return 2;
    } else {
        switch(true) {
            case size == 'lg':
                return 5;
            case size == 'md':
                return 4;
            case size == 'sm':
                return 3;
            case size == 'xs':
                return 2;
            default:
                return 2;

        }
    }
}

export {getResponsiveCarouselLength}