import {getResponsiveCarouselLength} from "./getResponsiveCarouselLength";

function initializeMultiItemCarousels() {
    $('.carousel-container').each(function() {
        var carousel = $(this),
            carousel_slider = carousel.find('.multi-item-carousel'),
            carousel_controls = carousel.find('.carousel-control'),
            carousel_id = carousel_slider.attr('id'),
            carousel_length = 5;

        if (carousel_slider.hasClass('six_items')) {
            carousel_length = 6;
        }

        if (carousel_slider.hasClass('two_items')) {
            carousel_length = 2;
        }

        var multi_item_carousel_lazyload_check = setInterval(function () {
            if (carousel.find('.multi-item-carousel').hasClass('lazyloaded')) {

                carousel_controls.toggle();
                carousel_slider.children('.item').css('display', 'block');

                carousel_slider.slick({
                    dots: false,
                    mobileFirst: true,
                    lazyLoad: 'ondemand',
                    speed: 300,
                    slidesToShow: getResponsiveCarouselLength(carousel_length),
                    slidesToScroll: 1,
                    infinite: false,
                    swipeToSlide: true,
                    rows: 0,
                    prevArrow: '.' + carousel_id + '.arrow-left',
                    nextArrow: '.' + carousel_id + '.arrow-right',
                    responsive: [
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: getResponsiveCarouselLength(carousel_length, 'xs')
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: getResponsiveCarouselLength(carousel_length, 'sm')
                            }
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: getResponsiveCarouselLength(carousel_length, 'md')
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: getResponsiveCarouselLength(carousel_length, 'lg')
                            }
                        }
                    ]
                })

                carousel.children('.slick-arrow').removeClass('slick-hidden');

                carousel.find(".arrow-left").hide();

                if (carousel.find('.item:last').hasClass('slick-active')) {
                    carousel.find(".arrow-right").hide();
                }

                carousel_slider.find('.slick-active:last').addClass('transparent-border');

                carousel_slider.on('beforeChange', function () {
                    carousel_slider.find('.slick-active').removeClass('transparent-border');
                })

                carousel_slider.on('afterChange', function () {
                    if ($(this).find(".item:first").hasClass("slick-active")) {
                        carousel.find(".arrow-left").hide();
                    } else {
                        carousel.find(".arrow-left").show();
                    }

                    if ($(this).find(".item:last").hasClass("slick-active")) {
                        carousel.find(".arrow-right").hide();
                    } else {
                        carousel.find(".arrow-right").show();
                    }

                    carousel_slider.find('.slick-active:last').addClass('transparent-border');
                });

                clearInterval(multi_item_carousel_lazyload_check);
            }
        }, 50);
    });
}

export {initializeMultiItemCarousels}